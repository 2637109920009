<template>
  <c-box mt="10px">
    <template v-for="(step, index) in steps">
      <CardStep
        v-if="index === stepIndex"
        :key="index"
        :current="index + 1"
        :total="steps.length"
        :is-prev="index !== 0"
        :is-next="true"
        @prev="stepIndex--"
        @next="onNext()"
      >
        <c-text color="brand.900">Pilih Indikator Pertanyaan</c-text>
        <c-text font-size="xl" font-weight="500">{{ step.title }}</c-text>
        <c-flex mt="30px" flex-dir="column">
          <c-checkbox
            v-for="(item, index) in step.questions"
            :key="index"
            v-model="item.checked"
            :is-disabled="!item.editable"
            variant-color="brand"
          >
            <c-flex>
              <template v-if="isDevelopment">
                <small>({{item.id}})</small>
              </template>
              <c-text>{{ item.question }}</c-text>
            </c-flex>
          </c-checkbox>
        </c-flex>
        <template #next>
          <c-text v-if="index === steps.length - 1">Submit</c-text>
          <c-text v-else>Selanjutnya</c-text>
        </template>
      </CardStep>
    </template>
  </c-box>
</template>

<script>
import CardStep from "@/views/nutritionists/progress-tracker/master/card-step"
import _ from "lodash"

export default {
  name: "ProgressStepEditIndex",
  components: { CardStep },
  data() {
    return {
      stepIndex: 0,
    }
  },
  computed: {
    steps() {
      let categories = this.$store.state.nutriProgressTracker.categories
      let steps = categories.map((category) => {
        let questions =
          this.$store.getters["nutriProgressTracker/getQuestionsFor"](category)
        // questions = questions.filter(it => it.checked)
        questions = _.orderBy(questions, "order")
        return {
          title: _.startCase(_.camelCase(category)),
          questions,
        }
      })

      return steps
    },
  },
  async mounted() {
    window.__submit = this.submit
    await this.$store.dispatch("nutriProgressTracker/getQuestions")
    await this.$store.dispatch(
      "nutriProgressTracker/getQuestionsForClientId",
      this.clientId
    )
  },
  methods: {
    onNext() {
      if (this.stepIndex < this.steps.length - 1) {
        this.stepIndex++
      } else {
        this.submit().then(() =>
          this.$router.replace({ name: "nutri.progress-tracker.master" })
        )
      }
    },
    async submit() {
      const questions = this.steps.flatMap((it) =>
        it.questions.filter((q) => q.checked || q.editable === false)
      )
      await this.$store.dispatch(
        "nutriProgressTracker/setQuestionsForClientId",
        {
          clientId: this.clientId,
          questions,
        }
      )
    },
  },
}
</script>

<style scoped></style>
